<template>
  <k-crud-layout :search.sync="searchQuery" :filters.sync="filters">
    <template #header>{{ $t('approvals.pageTitle') }}</template>
    <template #view.list>
      <k-crud-table ref="table"
                    :headers="crudHeaders"
                    :index-request="indexRequest"
                    :search="searchQuery"
                    :params="filters"
                    language-prefix="approvals.crudHeaders"
                    resource="approvals">
        <template #item.date="{item: {date}}">
          <span>{{ dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL') }}</span>
        </template>
        <template #actions="{item}">
          <v-btn icon :to="{name: 'approvals.show', params: {approvalId: item.id}}">
            <v-icon>$visible</v-icon>
          </v-btn>
        </template>
      </k-crud-table>
    </template>
    <template #filters="{attrs, on}">
      <ApprovalsFilter
          v-bind="attrs"
          v-on="on"
      />
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import { Approval } from '@/application/models/Approval.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { approvalIndex } from '@/modules/approvals/api';
import dayjs from '@/plugins/dayjs.js';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import ApprovalsFilter from '@/modules/approvals/components/ApprovalsFilter.vue';

export default {
  name: 'ApprovalTable',
  mixins: [querySyncMixin],
  components: {
    ApprovalsFilter,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      filters: {},
      searchQuery: '',
      approvals: [],
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'dealerCodeExt',
          language: 'dealerCodeExt'
        },
        {
          value: 'storeName',
          language: 'storeName',
        },
        {
          value: 'id',
          language: 'orderId',
        },
        {
          value: 'date',
          language: 'date',
        },
        {
          value: 'totalApprovers',
          language: 'approvers',
        },
      ];
    },
  },
  methods: {
    dayjs,
    async indexRequest(...args) {
      const response = await approvalIndex(...args);
      this.approvals = response.data.data.map(approval => new Approval().mapResponse(approval));
      return {
        data: {
          meta: response.data.meta,
          data: this.approvals,
        },
      };
    },
  },
};
</script>
