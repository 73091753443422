<template>
  <k-field-group language-prefix="approvals.filterFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('actions.applyFilters')"
               :title="$t('approvals.filterTitle')"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <StoreAutocomplete :return-object="false"
                         v-model="form.storeId"
                         field="store"
                         hide-details
                         item-text="name"
                         item-value="id"
                         class="mb-2 pb-1"/>
      <KDateField v-model="form.createdFrom" field="dateFrom" grid="col-sm-12 mb-2 pb-1" hide-details/>
      <KDateField v-model="form.createdTo" field="dateTo" grid="col-sm-12 mb-2 pb-1" hide-details/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import StoreAutocomplete from '@/modules/store/components/StoreAutocomplete.vue';

export default {
  name: 'ApprovalsFilter',
  inheritAttrs: false,
  components: {
    StoreAutocomplete,
    KSidebar,
    KFieldGroup,
    KDateField,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    form: {},
  }),
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.cleanFilters(this.form);
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    cleanFilters(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
