import Model from './model';

export class Approval extends Model {
  approvals = 0;
  date = '';
  id = 8;
  nextInLine = null;
  storeName = '';
  dealerCodeExt = '';
  totalApprovers = 3;

  mapForRequest() {
    return {
      ...this,
    };
  }
}
